const APP_ENV = process.env.REACT_APP_APP_ENV || 'dev'

let Config

if (APP_ENV === 'dev') {
    Config = {
        APP_ENV: APP_ENV,
        apiUrl: 'http://localhost:4500/api/v1/user',
        mediaUrl: 'http://localhost:4500/media/',
        USER_WEB_URL: 'http://localhost:3000',
        persona_mode: 'sandbox',
        persona_template_id: 'itmpl_Ygs16MKTkA6obnF8C3Rb17dm',
        s3Endpoint: 'https://crewmate-media-local.s3.us-west-2.amazonaws.com/',
        // googleAPI_KEY: "AIzaSyBXi4YfjakPz6aE2hz_8tIg3J85c8ZmRXE",
        googleAPI_KEY: 'AIzaSyBB_rVhDxVEMbjEp5LkCB8m8_JwRY8N5is',
        GOOGLE_CLIENTID: '640276684298-k3h73qel57b2i14sebenr1klcekd4f63.apps.googleusercontent.com',
        // GOOGLE_CLIENTID:
        //   "1038466327649-d0h5kif8r3qffravcd7uhf2hon4ci8v2.apps.googleusercontent.com",
        FACEBOOK_APPID: '237438817209817',
        ENABLE_SELFIE_AND_ID_VERIFICATION_CHECKPOINT: false
    }
} else if (APP_ENV === 'production') {
    console.log = () => { }
    Config = {
        APP_ENV: APP_ENV,
        apiUrl: 'https://api.crewmatesapp.com/api/v1/user',
        mediaUrl: 'https://api.crewmatesapp.com/media/',
        USER_WEB_URL: 'https://crewmatesapp.com',
        persona_mode: 'sandbox',
        persona_template_id: 'itmpl_Ygs16MKTkA6obnF8C3Rb17dm',
        s3Endpoint: 'https://crewmate-production.s3.us-west-2.amazonaws.com/',
        googleAPI_KEY: 'AIzaSyCyeyo0XBjhWKaKgxqv-jK-9yOGzyTUvDo',
        GOOGLE_CLIENTID: '640276684298-oein71eoqe5qlbu532avlep4h1837a1r.apps.googleusercontent.com',
        FACEBOOK_APPID: '237438817209817',
        ENABLE_SELFIE_AND_ID_VERIFICATION_CHECKPOINT: false
    }
} else if (APP_ENV === 'staging') {
    console.log = () => { }
    Config = {
        APP_ENV: APP_ENV,
        apiUrl: 'https://devapi.crewmatesapp.com/api/v1/user',
        mediaUrl: 'https://devapi.crewmatesapp.com/media/',
        USER_WEB_URL: 'https://dev.crewmatesapp.com',
        persona_mode: 'sandbox',
        persona_template_id: 'itmpl_Ygs16MKTkA6obnF8C3Rb17dm',
        s3Endpoint: 'https://crewmates-dev.s3.us-west-2.amazonaws.com/',
        googleAPI_KEY: 'AIzaSyBB_rVhDxVEMbjEp5LkCB8m8_JwRY8N5is',
        GOOGLE_CLIENTID: '640276684298-k3h73qel57b2i14sebenr1klcekd4f63.apps.googleusercontent.com',
        FACEBOOK_APPID: '237438817209817',
        ENABLE_SELFIE_AND_ID_VERIFICATION_CHECKPOINT: false
    }
}
// else if (APP_ENV === "staging") {
//   Config = {
//     APP_ENV: APP_ENV,
//     apiUrl: "https://devapi.crewmatesapp.com/api/v1/user",
//     mediaUrl: "https://devapi.crewmatesapp.com//media/",
//     USER_WEB_URL: "https://dev.crewmatesapp.com",
//     persona_mode: "sandbox",
//     persona_template_id: "itmpl_Ygs16MKTkA6obnF8C3Rb17dm",
//     s3Endpoint: "https://crewmates-dev.s3.us-west-2.amazonaws.com/",
//     // googleAPI_KEY: "AIzaSyBXi4YfjakPz6aE2hz_8tIg3J85c8ZmRXE",
//     googleAPI_KEY: "AIzaSyBB_rVhDxVEMbjEp5LkCB8m8_JwRY8N5is",
//     // GOOGLE_CLIENTID:
//     //   "1038466327649-d0h5kif8r3qffravcd7uhf2hon4ci8v2.apps.googleusercontent.com",
//     GOOGLE_CLIENTID:'640276684298-k3h73qel57b2i14sebenr1klcekd4f63.apps.googleusercontent.com',
//     FACEBOOK_APPID: "237438817209817",
//   };
// }
// else if (APP_ENV === "production") {
//   console.log=()=>{}
//   Config = {
//     APP_ENV: APP_ENV,
//     apiUrl: "https://api.crewmatesapp.com/api/v1/user",
//     mediaUrl: "https://api.crewmatesapp.com/media/",
//     USER_WEB_URL: "https://crewmatesapp.com",
//     persona_mode: "sandbox",
//     persona_template_id: "itmpl_Ygs16MKTkA6obnF8C3Rb17dm",
//     s3Endpoint: "https://crewmate-production.s3.us-west-2.amazonaws.com/",
//     googleAPI_KEY: "AIzaSyCyeyo0XBjhWKaKgxqv-jK-9yOGzyTUvDo",
//     GOOGLE_CLIENTID:
//       "640276684298-oein71eoqe5qlbu532avlep4h1837a1r.apps.googleusercontent.com",
//     FACEBOOK_APPID: "237438817209817",
//   };
// }

export const AppConfig = Config
